import * as React from "react"

import { Link as GatsbyLink } from "gatsby"
import { linkStyles } from "./link.styles"
import type { GatsbyLinkProps } from "gatsby"

type LinkProps = React.PropsWithoutRef<GatsbyLinkProps<unknown>>

export const Link: React.FC<LinkProps> = ({ children, to, ...props }) => {
  const styles = linkStyles({})

  const internal = /^\/(?!\/)/.test(to)
  if (internal) {
    return (
      <GatsbyLink to={to} css={styles} {...props}>
        {children}
      </GatsbyLink>
    )
  }

  return (
    <a
      href={to}
      css={styles}
      rel="noreferrer noopener"
      target="_blank"
      {...props}
    >
      {children}
    </a>
  )
}
