import { useStaticQuery, graphql } from "gatsby"
import { abstractStyles } from "../components/elements/abstract.styles"
import { Heading } from "../components/elements/heading"
import { Link } from "../components/elements/link"
import { Group } from "../components/layout/group"
import { isBrowser } from "../lib/is-browser"

type QueryProps = {
  title: string
}

type StaticQuery = {
  site: {
    siteMetadata: QueryProps
  }
}

const ErrorPage: React.FC = () => {
  const {
    site: { siteMetadata },
  } = useStaticQuery<StaticQuery>(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Group
      variant="column"
      options={{ center: true }}
      style={{
        maxWidth: 400,
        margin: "auto",
        marginTop: "25vh",
        padding: 24,
      }}
    >
      <p
        css={[
          abstractStyles({ variant: "small" }),
          { display: "inline-block" },
        ]}
      >
        <Link to="/">{siteMetadata.title} made with Knappsack</Link>
      </p>
      <Heading is="h1" variant="medium">
        Oops, this is an error!
      </Heading>
      <p css={abstractStyles({ variant: "medium" })}>
        The requested URL {isBrowser && location.pathname} was not found on this
        server. That's all we know.
      </p>
      <p css={abstractStyles({ variant: "small" })}>(＠＾◡＾)</p>
    </Group>
  )
}

export default ErrorPage
