import { mediaQuery } from "../../styles/media-query"
import { focusStyles } from "../models/text-section.styles"
import type { StylesProps, Variants } from "../../styles/types"
import type { CSSObject } from "@emotion/react"

type VariantStyle = string

const base: CSSObject = {
  cursor: "pointer",
  ":focus": focusStyles,
}

const variants: Variants<VariantStyle> = {}

export type LinkStylesProps = {
  variant?: VariantStyle
}

export const linkStyles: StylesProps<LinkStylesProps> = ({ variant = "" }) => {
  return mediaQuery([base, variants[variant]])
}
